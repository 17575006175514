const productsCatDrawer = () => {
  const productsCatDrawerDOM = document.querySelector<HTMLElement>("#js-productsCatDrawer");
  const productsCatDrawerTriggerDOM = document.querySelector<HTMLElement>(".js-productsCatDrawer-trigger");

  productsCatDrawerDOM?.addEventListener('click', function() {
    this.classList.toggle('active')
    productsCatDrawerTriggerDOM?.classList.toggle('active')
  })
};

export default productsCatDrawer;
