import { body } from "../main"

const humbargerDom = document.querySelector<HTMLElement>('.bar')
const humbargerOverLay = document.querySelector<HTMLElement>('.humbarger-overLay')

const handleHumbargerIcon = () => {
  if(humbargerDom) {
    humbargerDom.addEventListener( 'click', () => {
      humbargerDom.classList.toggle('is-active')
      body?.classList.toggle('no-scroll')
    })
  }
  if(humbargerOverLay && humbargerDom) {
    humbargerOverLay.addEventListener( 'click', () => {
      humbargerDom.classList.toggle('is-active')
      body?.classList.toggle('no-scroll')
    })
  }
}

const handleNavChild = () => {
  const types = ['rental', 'order', 'kimono', 'tuxedo', 'guest']
  types.map(type => {
    const navChildDom = document.querySelector<HTMLButtonElement>(`#js-header-navBtn_${type}`)
    const navChildWrapDom = document.querySelector<HTMLButtonElement>(`#js-header-navListChildWrap_${type}`)
    navChildDom?.addEventListener('click', function() {
      this.classList.toggle('is-active')
      navChildWrapDom?.classList.toggle('is-active')
    })
  })
}

const humbarger = () => {
  handleHumbargerIcon()
  handleNavChild()
}

export default humbarger