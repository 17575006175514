const infiniteScroll = () => {
  const trigger = document.querySelector<HTMLElement>(".js-infiniteScrollTrigger");
  const infiniteScrollEls = document.querySelectorAll<HTMLElement>(".js-infiniteScroll .js-infiniteScrollItem");
  let postActiveNum = document.querySelector<HTMLElement>(".p-archive-postActiveNum");

  const perPostNum = 12;

  if (infiniteScrollEls.length <= perPostNum) trigger?.classList.add("is-limited");

  // ページ遷移後、表示したい数分.js-infiniteScrollItemのclassをactive追加
  for (let i = 0; i < perPostNum; i++) {
    infiniteScrollEls[i]?.classList.add("active");
  }

  // 表示している記事数を動的に変更
  if (postActiveNum) {
    if (infiniteScrollEls.length <= perPostNum) {
      postActiveNum.innerText = String(infiniteScrollEls.length);
    } else {
      postActiveNum.innerText = String(perPostNum);
    }
  }

  let activeItemNum = perPostNum;
  trigger?.addEventListener("click", function () {
    let activeLimit;
    activeLimit = activeItemNum + perPostNum;
    if (activeLimit == 13) {
      if (postActiveNum) postActiveNum.innerText = String(infiniteScrollEls.length);
      return;
    } else {
      if (infiniteScrollEls.length < activeLimit)
        activeLimit = infiniteScrollEls.length;
      if (postActiveNum) postActiveNum.innerText = String(activeLimit);
      for (let i = 0; i < activeLimit; i++) {
        infiniteScrollEls[i].classList.add("active");
      }
      if (activeLimit === infiniteScrollEls.length) {
        this.classList.add("is-limited");
        return (activeItemNum = 1);
      } else return (activeItemNum = activeLimit);
    }
  });
};

export default infiniteScroll;
