import Swiper, { Autoplay, EffectFade } from 'swiper'

const swiperMv = () => {
  new Swiper('.swiperMv', {
    modules: [Autoplay, EffectFade],
    spaceBetween: 30,
    effect: 'fade',
    loop: true,
    speed: 4000,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  })
}

export default swiperMv
