const headerBar = document?.querySelector<HTMLElement>("#js-bar");
const header = document?.querySelector<HTMLElement>("#js-header");
const headerNavOnlyFirstView = document?.querySelector<HTMLElement>(".header-nav.only-firstView");

const topNav = () => {
  window.addEventListener("scroll", () => {
    let scrollTop = window.pageYOffset;
    if (scrollTop > 400) {
      header?.classList.remove("header-top");
      headerBar?.classList.remove("top-hidden");
      headerNavOnlyFirstView?.classList.remove("active");
    } else {
      header?.classList.add("header-top");
      headerBar?.classList.add("top-hidden");
      headerNavOnlyFirstView?.classList.add("active");
    }
  });
};

export default topNav;
