const firstVisitFormHandlerDOM = document.querySelector<HTMLElement>(
  '#js-reserve-firstVisitFormHandler'
)
const notFirstVisitFormHandlerDOM = document.querySelector<HTMLElement>(
  '#js-reserve-notFirstVisitFormHandler'
)
const firstVisitFormWrapDOM = document.querySelector<HTMLElement>(
  '#js-reserve-firstVisitFormWrap'
)
const notFirstVisitFormWrapDOM = document.querySelector<HTMLElement>(
  '#js-reserve-notFirstVisitFormWrap'
)

const accodionConditionalFirstVisitHandler = () => {
  firstVisitFormHandlerDOM?.addEventListener('click', function () {
    this.classList.toggle('is-active')
    firstVisitFormWrapDOM?.classList.toggle('is-active')

    // アコーディオンを閉じた際に、エラー表示をreset
    const fittingTypeWrapDOMHasValid = document.querySelector<HTMLInputElement>(
      '#js-reserve-firstVisitFormWrap.is-active #fitting-type.wpcf7-not-valid'
    )
    const clothesSizeDOMHasValid = document.querySelector<HTMLSelectElement>(
      '#js-reserve-firstVisitFormWrap.is-active #clothes-size.wpcf7-not-valid'
    )
    if (firstVisitFormWrapDOM?.classList.contains('is-active')) {
      // checkRequiredInput()
    } else if (
      !firstVisitFormWrapDOM?.classList.contains('is-active') &&
      fittingTypeWrapDOMHasValid &&
      clothesSizeDOMHasValid
    ) {
      fittingTypeWrapDOMHasValid.classList.remove('wpcf7-not-valid')
      clothesSizeDOMHasValid.classList.remove('wpcf7-not-valid')
    } else if (
      !firstVisitFormWrapDOM?.classList.contains('is-active') &&
      fittingTypeWrapDOMHasValid
    ) {
      fittingTypeWrapDOMHasValid.classList.remove('wpcf7-not-valid')
    } else if (
      !firstVisitFormWrapDOM?.classList.contains('is-active') &&
      clothesSizeDOMHasValid
    ) {
      clothesSizeDOMHasValid.classList.remove('wpcf7-not-valid')
    }
  })
  notFirstVisitFormHandlerDOM?.addEventListener('click', function () {
    this.classList.toggle('is-active')
    notFirstVisitFormWrapDOM?.classList.toggle('is-active')
  })
}

const formDateColor = () => {
  const dateDOMs =
    document.querySelectorAll<HTMLInputElement>('input[type="date"]')
  dateDOMs.forEach((el) => {
    el.classList.add('empty')
    el.addEventListener('change', function () {
      if (this.value === '') {
        this.classList.add('empty')
      } else {
        this.classList.remove('empty')
      }
    })
  })
}

const checkRequiredInput = () => {
  const confirmBtnDOM = document.querySelector<HTMLElement>(
    '.p-reserve-submitBtn'
  )
  confirmBtnDOM?.addEventListener('click', (e: Event) => {
    const fittingTypeCheckedInputArr =
      document.querySelectorAll<HTMLInputElement>(
        '#js-reserve-firstVisitFormWrap.is-active #fitting-type input:checked'
      )
    const clothesSizeDOM = document.querySelector<HTMLSelectElement>(
      '#js-reserve-firstVisitFormWrap.is-active #clothes-size'
    )
    const fittingTypeWrapDOM = document.querySelector<HTMLInputElement>(
      '#js-reserve-firstVisitFormWrap.is-active #fitting-type'
    )

    const nameVal =
      document.querySelector<HTMLInputElement>('form #name')?.value
    const nameFuriganaVal = document.querySelector<HTMLInputElement>(
      'form #name-furigana'
    )?.value
    const emailVal =
      document.querySelector<HTMLInputElement>('form #email')?.value
    const telVal = document.querySelector<HTMLInputElement>('form #tel')?.value
    const firstHopeDateVal = document.querySelector<HTMLInputElement>(
      'form #first-hope-date'
    )?.value
    const firstHopeTimeVal = document.querySelector<HTMLInputElement>(
      'form #first-hope-time'
    )?.value
    const secondDateVal =
      document.querySelector<HTMLInputElement>('form #second-date')?.value
    const secondHopeTimeVal = document.querySelector<HTMLInputElement>(
      'form #second-hope-time'
    )?.value

    if (
      nameVal &&
      nameFuriganaVal &&
      emailVal &&
      telVal &&
      firstHopeDateVal &&
      firstHopeTimeVal &&
      secondDateVal &&
      secondHopeTimeVal
    ) {
      // ご試着希望のお衣裳内容が未入力の際のvalidate
      if (fittingTypeCheckedInputArr.length === 0) {
        e.preventDefault()
        fittingTypeWrapDOM?.classList.add('wpcf7-not-valid')
      } else {
        fittingTypeWrapDOM?.classList.remove('wpcf7-not-valid')
      }

      // お洋服のサイズが未入力の際のvalidate
      if (clothesSizeDOM?.value === '') {
        e.preventDefault()
        clothesSizeDOM.classList.add('wpcf7-not-valid')
      } else {
        clothesSizeDOM?.classList.remove('wpcf7-not-valid')
      }
    } else {
      // ご試着希望のお衣裳内容が未入力の際のvalidate
      if (fittingTypeCheckedInputArr.length === 0) {
        fittingTypeWrapDOM?.classList.add('wpcf7-not-valid')
      } else {
        fittingTypeWrapDOM?.classList.remove('wpcf7-not-valid')
      }

      // お洋服のサイズが未入力の際のvalidate
      if (clothesSizeDOM?.value === '') {
        clothesSizeDOM.classList.add('wpcf7-not-valid')
      } else {
        clothesSizeDOM?.classList.remove('wpcf7-not-valid')
      }
    }
  })
}

const removeNullValElementInConfirmReserve = () => {
  const firstVisitFormWrapDOM = document.querySelector<HTMLElement>(
    '#js-reserveConfirm-firstVisitFormWrap'
  )
  const notFirstVisitFormWrapDOM = document.querySelector<HTMLElement>(
    '#js-reserveConfirm-notFirstVisitFormWrap'
  )
  const fittingTypeVal = document.querySelector<HTMLElement>(
    '#js-reserveConfirm-fittingType dd'
  )?.innerText
  const anythingHopeForNotFirstVisitVal = document.querySelector<HTMLElement>(
    '#js-reserveConfirm-anythingHopeForNotFirstVisit dd'
  )?.innerText
  if (!fittingTypeVal) firstVisitFormWrapDOM?.remove()
  if (!anythingHopeForNotFirstVisitVal) notFirstVisitFormWrapDOM?.remove()
}

const form = () => {
  accodionConditionalFirstVisitHandler()
  formDateColor()
  checkRequiredInput()
  removeNullValElementInConfirmReserve()
}

export default form
