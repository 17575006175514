import Swiper, {Navigation, Autoplay} from "swiper";

const swiperExNews = () => {
    new Swiper(".swiperExNews", {
        modules: [Navigation, Autoplay],
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

export default swiperExNews