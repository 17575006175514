import { body } from "../main";

const modalTriggers = document.querySelectorAll<HTMLElement>(".js-lineModal-trigger");
const modal = document.querySelector<HTMLElement>("#js-lineModal");
const modalOverlay = document.querySelector<HTMLElement>("#js-lineModalOverlay");

const lineModal = () => {
  modalTriggers.forEach(el => {
    el.addEventListener('click', () => {
      body?.classList.add('no-scroll')
      modal?.classList.add('active')
    })
  });
  modalOverlay?.addEventListener('click', () => {
    body?.classList.remove('no-scroll')
    modal?.classList.remove('active')
  })
};

export default lineModal;
