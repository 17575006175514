const productDescWrapDOM = document.querySelector<HTMLElement>('#js-products-singleDescWrap')
const productDescWrapOpenTriggerDOM = document.querySelector<HTMLElement>('#js-products-singleDescWrapOpenTrigger')
const productDescWrapCloseTriggerDOM = document.querySelector<HTMLElement>('#js-products-singleDescWrapCloseTrigger')

const productsDescTrigger = () => {
  productDescWrapOpenTriggerDOM?.addEventListener('click', function() {
    this.classList.remove('active')
    productDescWrapCloseTriggerDOM?.classList.add('active')
    productDescWrapDOM?.classList.add('active')
  })
  productDescWrapCloseTriggerDOM?.addEventListener('click', function() {
    this.classList.remove('active')
    productDescWrapOpenTriggerDOM?.classList.add('active')
    productDescWrapDOM?.classList.remove('active')
  })
}

export default productsDescTrigger