import Swiper, { Navigation, Autoplay, EffectFade } from 'swiper'

const swiperAccess = () => {
  new Swiper('.swiperAccess', {
    modules: [Navigation, Autoplay, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    effect: 'fade',
    speed: 3000,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
}

export default swiperAccess
