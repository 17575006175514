const accodionDom = document.querySelectorAll<HTMLElement>(".js-accodion-trigger");

const accodion = () => {
  accodionDom.forEach((item) => {
    item.addEventListener("click", function() {
      this.classList.toggle("is-active");
    });
  });
};

export default accodion;
