import "./scss/style.scss";

import swiperProducts from "./ts/swiperProducts";
import swiperMv from "./ts/swiperMv";
import swiperExNews from "./ts/swiperExNews";
import swiperAccess from "./ts/swiperAccess";

import accodion from "./ts/accodion";
import form from "./ts/form";
import humbarger from "./ts/humbarger";
import infiniteScroll from "./ts/infiniteScroll";
import lineModal from "./ts/lineModal";
import partyReportFilter from "./ts/partyReportFilter";
import productsDescTrigger from "./ts/singleProducts";
import productsCatDrawer from "./ts/productsCatDrawer";
import topNav from "./ts/top-header";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// windowに何かつっこんだ場合の型宣言です
declare global {
  interface Window {
    get_theme_file_url: String;
  }
}

export const body = document.querySelector<HTMLElement>("body");

const pathName: string = location.pathname;
const isTopPage: boolean = pathName === "/";
const spWindowSizeLimit = 601;

swiperMv();
swiperProducts();
swiperExNews();
swiperAccess();

accodion();
form();
humbarger();
infiniteScroll();
lineModal();
productsDescTrigger();
productsCatDrawer();

if (isTopPage) topNav();
if (window.innerWidth < spWindowSizeLimit) partyReportFilter();

const app = document.querySelector<HTMLDivElement>("#app")!;

if (app) {
  app.innerHTML = `<h1>Hello Vite!</h1>`;
}
