const partyReportFilter = () => {
  const partyReportItemDOMs = document.querySelectorAll(".js-filter-target");

  const options = {
    root: null,
    rootMargin: "-20% 0px -20% 0px",
    threshold: 0.5,
  };

  const addClass = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) entry.target.classList.add("crossing");
      else entry.target.classList.remove("crossing");
    });
  };

  const observer = new IntersectionObserver(addClass, options);

  partyReportItemDOMs.forEach((item) => {
    observer.observe(item);
  });
};

export default partyReportFilter;
